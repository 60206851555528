// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'online',
    back: true
  }, {
    prop: 'name'
  }, {
    prop: 'name_en'
  }, {
    prop: 'lat'
  }, {
    prop: 'lon'
  }, {
    prop: 'description',
    span: 24
  }, {
    prop: 'description_en',
    span: 24
  }, {
    prop: 'introduce',
    span: 12
  }, {
    prop: 'introduce_en',
    span: 12
  }, {
    prop: 'address'
  }, {
    prop: 'website'
  }, {
    prop: 'phone_number'
  }, {
    prop: 'email'
  }, {
    prop: 'usage',
    span: 24
  }, {
    prop: 'usage_en',
    span: 24
  }, {
    prop: 'traffic_strategy',
    span: 24
  }, {
    prop: 'traffic_strategy_en',
    span: 24
  }, {
    prop: 'tips',
    span: 24
  }, {
    prop: 'tips_en',
    span: 24
  }, {
    prop: 'global_labels'
  }, {
    prop: 'redeem_code'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }, {
    prop: 'image',
    image: true,
    span: 24
  }]
}
